/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.content-relation-file-list .input-container {
  width: 100%;
}
.content-relation-file-list table {
  width: 100%;
}
.content-relation-file-list table tr {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7;
}
.content-relation-file-list table td {
  text-align: left;
  vertical-align: middle;
  padding: 6px 20px 6px 20px;
}
.content-relation-file-list table .file-sort-handle {
  cursor: move;
}
.content-relation-file-list table td:nth-child(1) {
  width: 10px;
}
.content-relation-file-list table td:nth-child(2) {
  width: 50px;
}
.content-relation-file-list .mdi-delete {
  cursor: pointer;
}
.content-relation-file-list .mdi-content-save {
  cursor: pointer;
}
.content-relation-file-list .mdi:hover {
  color: #0068ff;
}
.content-relation-file-list .file-manager-button {
  cursor: pointer;
  margin: 10px;
}
.content-relation-file-list .file-manager-button:hover i {
  color: #0068ff;
}
.ui-sortable-helper .hide-when-dragging {
  display: none;
}
